
























































































import { Vue, Component } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { OrganisationDetails } from "@/models/OrganisationDetails";
import { organisationModule } from "@/store/modules/OrganisationModule";

@Component
export default class ManualEmissions extends Vue {
  public data: any = [];
  public filter: any = { year: null, month: null, emissionTypeId: null };

  private get organisation(): OrganisationDetails {
    return organisationModule.organisationDetails;
  }

  private async getManualEmissions() {
    this.data = await adminService.getManualEmissions(
      this.organisation.id,
      this.filter
    );
  }

  public async mounted() {
    await this.getManualEmissions();

    if (this.data.years && this.data.years.length > 0) {
      this.filter.year = this.data.years[0];
    }

    this.filter.month = 1;
    this.filter.emissionTypeId = this.organisation.emissionTypes[0].id;
  }
}
